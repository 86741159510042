import ChatChannelSettingsModalButton from "@/apps/list/app/chat/button/ChatChannelSettingsModalButton"
import { ActiveAppModalProvider } from "@/apps/util/activeAppModalContext"
import { useLabel } from "@/core/context/LabelsContext"
import {
  StreamChatUserData,
  useStreamChannel,
  useStreamChat,
} from "@/core/context/StreamChatContext"
import ChatIcon from "@/core/ui/images/empty-state-illustrations/chat.svg"
import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import styleIf from "@assets/style/util/styleIf"
import useCanTriggerAIMessageSuggestion from "@components/ai/hooks/useCanTriggerAIMessageSuggestion"
import ChatChannelClickedUserPopover from "@components/chat/channel/clicked-user/ChatChannelClickedUserPopover"
import CreateMessageWithAIButton from "@components/chat/channel/CreateMessageWithAIButton"
import ChatChannelAvatar from "@components/chat/channel/detail/avatar/ChatChannelAvatar"
import ChatChannelDetailDateSeparator from "@components/chat/channel/detail/date-separator/ChatChannelDetailDateSeparator"
import ChatChannelSimpleMessage from "@components/chat/channel/detail/message/ChatChannelMessage"
import ChatChannelQuotedMessage from "@components/chat/channel/detail/quoted-message/ChatChannelQuotedMessage"
import ChatChannelQuotedMessagePreview from "@components/chat/channel/detail/quoted-message/ChatChannelQuotedMessagePreview"
import ChatChannelDetailFooterSendButton from "@components/chat/channel/detail/send-button/ChatChannelDetailFooterSendButton"
import ChatChannelEditMessage from "@components/chat/channel/edit/ChatChannelEditMessage"
import ChatChannelEmpty from "@components/chat/channel/empty/ChatChannelEmpty"
import JumpToMessage from "@components/chat/channel/JumpToMessage"
import ChatChannelMessageRepliesCountButton from "@components/chat/channel/replies/ChatChannelMessageRepliesCountButton"
import ChatChannelThreadHeader from "@components/chat/channel/thread/ChatChannelThreadHeader"
import ChatChannelThreadStart from "@components/chat/channel/thread/ChatChannelThreadStart"
import ChatChannelTriggerProvider from "@components/chat/channel/trigger/ChatChannelTriggerProvider"
import { ChatChannelFragment$key } from "@components/chat/channel/__generated__/ChatChannelFragment.graphql"
import { ChatChannel_EmptyStateIndicatorFragment$key } from "@components/chat/channel/__generated__/ChatChannel_EmptyStateIndicatorFragment.graphql"
import { DiscoButton, DiscoEmptyState, DiscoSpinner } from "@disco-ui"
import { TestIDProps } from "@utils/typeUtils"
import React, { useCallback, useState } from "react"
import { graphql, useFragment } from "react-relay"
import { UserResponse } from "stream-chat"
import { Channel, SimpleReactionsList, useMessageContext } from "stream-chat-react"
import { DefaultStreamChatGenerics } from "stream-chat-react/dist/types/types"

interface Props extends TestIDProps {
  children?: React.ReactNode
  /** True if thread is used as the main component */
  mainThread?: boolean
  chatChannelKey: ChatChannelFragment$key
}

function ChatChannel(props: Props) {
  const { children, mainThread, testid = "ChatChannel", chatChannelKey } = props
  const { isConnected } = useStreamChat()

  const classes = useStyles({ isMainThread: Boolean(mainThread) })

  const [clickedUser, setClickedUser] = useState<UserResponse | undefined>()
  const [clickedUserTarget, setClickedUserTarget] = useState<HTMLButtonElement>()

  const chatChannel = useFragment<ChatChannelFragment$key>(
    graphql`
      fragment ChatChannelFragment on ChatChannel {
        externalChannelId
        ...ChatChannel_EmptyStateIndicatorFragment
        ...ChatChannelMessageFragment
        ...ChatChannelEditMessageFragment
      }
    `,
    chatChannelKey
  )

  const streamChannel = useStreamChannel(chatChannel.externalChannelId)

  const onMentionsClick = useCallback(
    (
      event: React.BaseSyntheticEvent,
      user: UserResponse<DefaultStreamChatGenerics> | undefined
    ) => {
      event.stopPropagation()
      setClickedUser(user)
      setClickedUserTarget(event.target)
    },
    [setClickedUser]
  )

  // ! If this case is getting hit and you are adding a stream channel,
  // ! make sure you use the useAddStreamChannelToContext() hook before trying to render this component
  if (!streamChannel) return <DiscoSpinner absoluteCenter />

  // Don't render channel if client is not connected
  if (!isConnected) return null

  return (
    <div className={classes.channel} data-testid={testid}>
      <Channel
        channel={streamChannel}
        Avatar={Avatar}
        DateSeparator={ChatChannelDetailDateSeparator}
        VirtualMessage={(messageProps) => (
          <ChatChannelSimpleMessage {...messageProps} chatChannelKey={chatChannel} />
        )}
        SendButton={ChatChannelDetailFooterSendButton}
        TypingIndicator={() => null}
        MessageDeleted={() => null}
        ReactionsList={SimpleReactionsList}
        QuotedMessage={ChatChannelQuotedMessage}
        QuotedMessagePreview={ChatChannelQuotedMessagePreview}
        EmptyStateIndicator={(emptyStateIndicatorProps) => (
          <EmptyStateIndicator
            {...emptyStateIndicatorProps}
            chatChannelKey={chatChannel}
          />
        )}
        TriggerProvider={ChatChannelTriggerProvider}
        onMentionsClick={onMentionsClick}
        ThreadHeader={mainThread ? () => null : ChatChannelThreadHeader}
        ThreadStart={ChatChannelThreadStart}
        MessageRepliesCountButton={ChatChannelMessageRepliesCountButton}
        EditMessageInput={(messageProps) => (
          <ChatChannelEditMessage {...messageProps} chatChannelKey={chatChannel} />
        )}
      >
        {children}
        {/* useChannelActionContext becomes available when nested within the Channel component */}
        <JumpToMessage streamChannel={streamChannel} />
      </Channel>

      {clickedUser && clickedUserTarget && (
        <ChatChannelClickedUserPopover
          clickedUser={clickedUser}
          clickedUserTarget={clickedUserTarget}
          setClickedUser={setClickedUser}
        />
      )}
    </div>
  )
}

function Avatar() {
  const { message } = useMessageContext()
  const user = message.user as StreamChatUserData | undefined

  if (!user) return null
  return <ChatChannelAvatar user={user} />
}

interface EmptyStateIndicatorProps {
  chatChannelKey: ChatChannel_EmptyStateIndicatorFragment$key | null
}

function EmptyStateIndicator({ chatChannelKey }: EmptyStateIndicatorProps) {
  const membersLabel = useLabel("organization_member")
  const classes = useEmptyStateIndicatorStyles()

  const chatChannel = useFragment<ChatChannel_EmptyStateIndicatorFragment$key>(
    graphql`
      fragment ChatChannel_EmptyStateIndicatorFragment on ChatChannel {
        id
        externalChannelId
        app {
          id
          customAppDescription
          ...ChatChannelSettingsModalButtonFragment
        }
      }
    `,
    chatChannelKey
  )

  const canTriggerAISuggestionForEntity = useCanTriggerAIMessageSuggestion({
    channelId: chatChannel?.id || "",
  })
  const streamChannel = useStreamChannel(chatChannel?.externalChannelId)
  const isThread = Boolean(streamChannel?.state.messages.length)

  // Only show this special bot suggestion UI if they can interact with the AI chat bot and the chat channel is public
  if (canTriggerAISuggestionForEntity && !isThread) {
    if (!chatChannel?.app?.customAppDescription) {
      return (
        <ActiveAppModalProvider>
          <div className={classes.emptyState}>
            <DiscoEmptyState
              testid={"ChatChannel.empty-state.add-description"}
              icon={<ChatIcon />}
              title={`Start chatting or add a Channel Description.`}
              subtitle={`Receive personalized prompt suggestions to help get the conversation going.`}
              buttons={
                <ChatChannelSettingsModalButton appKey={chatChannel!.app!}>
                  {(buttonProps) => (
                    <DiscoButton {...buttonProps} leftIcon={"add"}>
                      {"Channel Description"}
                    </DiscoButton>
                  )}
                </ChatChannelSettingsModalButton>
              }
            />
          </div>
        </ActiveAppModalProvider>
      )
    }
    return (
      <div className={classes.emptyState}>
        <DiscoEmptyState
          testid={"ChatChannel.empty-state.create-message-with-ai"}
          icon={<ChatIcon />}
          title={`No messages yet. Be the first to kick-start discussions.`}
          subtitle={`Help your ${membersLabel.plural} get the conversation going by sharing something here.`}
          buttons={<CreateMessageWithAIButton channelId={chatChannel.id} />}
        />
      </div>
    )
  }
  return (
    // If there are channel messages, then any new window from here must be a thread
    <ChatChannelEmpty state={isThread ? "thread" : "page"} />
  )
}

type StyleProps = {
  isMainThread: boolean
}

const useStyles = makeUseStyles((theme) => ({
  channel: ({ isMainThread }: StyleProps) => ({
    height: "100%",
    width: "100%",
    padding: 0,
    "& .str-chat": {
      marginTop: 0,
      height: "100%",

      "& .emoji-mart-emoji": {
        height: "20px",
      },
      "&__message-mention": {
        cursor: "pointer",
      },
      "&, & *": {
        fontFamily: theme.typography.fontFamily,
      },
      "&__container": {
        display: "flex",
        flexDirection: "column",
        height: "100%",
      },
      "&__message-replies-count-button": {
        color: theme.palette.primary.main,
      },
      "&__reverse-infinite-scroll": {
        paddingTop: 0,
      },
      "&__message-text-inner": {
        maxWidth: "none",
      },
      "&__message-team-form-footer button": {
        marginLeft: theme.spacing(2),
        cursor: "pointer",
      },
      "&__virtual-list": {
        padding: theme.spacing(0, 1),
        backgroundColor: theme.palette.background.paper,
        "&--thread": {
          padding: 0,
        },
        "div &-message-wrapper": {
          padding: 0,
        },
      },
      "& ol": {
        paddingLeft: theme.spacing(2),
      },
      "&__li": {
        margin: 0,
        "&:hover": {
          backgroundColor:
            theme.palette.type === "dark"
              ? theme.palette.groovy.onDark[500]
              : theme.palette.groovy.neutral[200],
          borderRadius: theme.measure.borderRadius.big,
        },
        "& .message__name-and-date": {
          display: "none",
        },
        "&--top, &--middle, &--bottom, &--single": {
          margin: 0,
          "& > div:first-child": {
            // Can't put any vertical margin on message list item or else the virtuoso auto
            // scroll-to-bottom behaviour can't calculate message height and doesn't scroll
            // all the way to the bottom
            margin: 0,
          },
        },
        "&--top > div:first-child": {
          padding: theme.spacing(0.5, 1.5, 0.25),
        },
        "&--middle > div:first-child": {
          padding: theme.spacing(0.25, 1.5),
        },
        "&--bottom > div:first-child": {
          padding: theme.spacing(0.25, 1.5, 0.5),
        },
        "&--bottom": {
          paddingBottom: 0,
        },
        "&--single": {
          padding: 0,
        },
        "&--bottom, &--middle": {
          "& .avatar": {
            // when collapsing avatar, we keep width, but don't want to display image or outline
            height: "0",
            border: "none",
            flexShrink: 0,
          },
          "& .avatar-placeholder": {
            height: "0 !important",
            background: "transparent",
            border: "none",
          },
          "& .avatar-placeholder > p:first-child": {
            display: "none",
          },
        },
        "&--top, &--single": {
          "& .message__name-and-date": {
            display: "flex",
          },
        },
      },
      "&__thread-header": {
        background: theme.palette.background.paper,
        "&:hover": {
          background: theme.palette.groovy.neutral[100],
        },
      },
      "&__thread": {
        maxWidth: isMainThread ? "100%" : "45%",
        padding: 0,
        borderRadius: 0,
        overflow: "initial",
        borderLeft: isMainThread ? "none" : theme.palette.constants.borderSmall,

        "& .str-chat__virtual-list": {
          padding: isMainThread ? 0 : theme.spacing(0, 1),
          overflowY: "initial",

          "&-message-wrapper": {
            padding: 0,
          },
          "& .str-chat__virtual-list-message-wrapper": {
            padding: 0,
          },
        },

        "&-list": {
          backgroundColor: theme.palette.background.paper,
          padding: theme.spacing(0, 1, 4, 1),
          height: "100dvh",
          "& > .message-container": {
            padding: theme.spacing(0, 2),
          },
          [theme.breakpoints.down("xs")]: {
            paddingLeft: theme.spacing(1.5),
          },
        },
        [theme.breakpoints.down("xs")]: {
          position: "absolute",
          margin: 0,
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          maxWidth: "none",
          zIndex: theme.zIndex.chatBar,
          borderRadius: theme.measure.borderRadius.big,
          backgroundColor: theme.palette.background.paper,
          ...styleIf(isMainThread, {
            position: "relative",
            zIndex: "unset",
            top: "unset",
            left: "unset",
          }),
        },
      },
      "&__channel": {
        ...styleIf(isMainThread, {
          maxHeight: "none",
        }),
      },

      "& .str-chat__gallery-image": {
        backgroundColor: "transparent",

        "& img": {
          borderRadius: "16px",
        },
      },

      "& .str-chat__modal__inner": {
        padding: 0,
        background: "none",
      },

      "& .image-gallery-content": {
        width: "70vw",
        height: "70vh",
        backgroundColor: "rgba(255, 255, 255, 0.5)",
        borderRadius: theme.measure.borderRadius.big,
        "& .image-gallery-index": {
          borderRadiusTopRight: theme.measure.borderRadius.big,
        },
        "& .image-gallery-slide-wrapper": {
          height: "100%",
          "& .image-gallery-swipe, & .image-gallery-slides, & .image-gallery-slide": {
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            "& img": {
              objectFit: "contain",
              width: "100%",
              height: "100%",
            },
          },
        },
        "&.fullscreen": {
          width: "100vw",
          height: "100vh",
        },
      },
    },
  }),
}))

const useEmptyStateIndicatorStyles = makeUseStyles({
  emptyState: {
    height: "100%",
    display: "flex",
  },
})

export default ChatChannel
